/**
 * creer l'instanciation de l'objet XHR qu'inporte le navigateur
 */
function getXMLHttpRequest() {
    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                "Msxml2.XMLHTTP.6.0",
                "Msxml2.XMLHTTP.3.0",
                "Msxml2.XMLHTTP",
                "Microsoft.XMLHTTP",
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) { }
            }
        } else {
            xhr = new XMLHttpRequest();
        }
    } else {
        return null;
    }
    return xhr;
}

/**
 * Transformation XML en tableau
 */
function traiteXmlQte(xmlDoc) {
    var options = xmlDoc.getElementsByTagName("option");
    var optionsListe = new Array();
    for (var i = 0; i < options.length; ++i) {
        var optionsListe2 = new Array();

        var id = options.item(i).getElementsByTagName("id");
        var label = options.item(i).getElementsByTagName("label");
        var color = options.item(i).getElementsByTagName("color");
        optionsListe2.push(
            id.item(0).firstChild.data,
            label.item(0).firstChild.data,
            color.item(0).firstChild.data
        );
        optionsListe.push(optionsListe2);
    }
    return optionsListe;
}

/**
 * Permet le non rafraichissement d'une page en exploitant l'objet XHR
 */
function ajax_file(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseText;
    else return false;
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader(
        "Content-type",
        "application/x-www-form-urlencoded"
    );
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseXML;
    else return false;
}

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

/**
 * Switch lang
 */
function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_uri_by_lang'),
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }
                location.href = data + force;
            }
        }
    });
}

/**
 * Shows button's underlying loader and triggers defined action
 */
var elmt;
var loader;
var prev_display;

function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

/**
 * AJAX DISCONNECTION
 * @param path_relative_root
 * @returns {boolean}
 */
function ajaxDeconnect(path_relative_root) {
    var provenance = window.location.pathname,
        cpt_client = new RegExp(
            /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
        ),
        response;

    // Get the current page's name
    provenance = provenance.substr(
        provenance.lastIndexOf("/"),
        provenance.lastIndexOf(".")
    );

    // This actually disconnects the client
    response = ajax_file(path_relative_root + "ajax_deconnect.php");

    // If there was an error
    if (!response) alert("Erreur lors de la déconnexion.");
    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href =
            path_relative_root + create_link("connexion_login");
    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".loader_scroll").fadeTo("fast", 1);
    }

    if ($('#scroll_origin').length) {
        if (!fromScrollBottom && fromScrollTop) {
            $('#scroll_origin').val(page);
        } else if (fromScrollBottom && !fromScrollTop) {
            $('#initial_page').val(page);
        } else {
            $('#scroll_origin, #initial_page').val(page);
        }
    }

    
    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop);
    } else {
        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, null);
    }
}

function paginate(page) {

    var form = document.getElementById('filter_sticky');

    if (form != null) {
        var total_element = form.getValue('totalElems', parseFloat),
            nb_prod = form.getValue('nb_prod', parseFloat),
            nb_total_page = Math.ceil(total_element / nb_prod),
            pagination = $('.pager_wrapper'),
            nb_prod_default = form.getValue('nb_prod_default', parseFloat),
            see_all = form.getValue('see_all', parseFloat),
            page = page || 1;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_reload_pagination.php'),
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {

            var productLoaded = $('.item:not(.push)').length;

            pagination.each(function () {
                if (see_all === 0 && nb_prod < total_element) {
                    this.innerHTML = res + '<span class="nbProdDisplayed">' + Translator.translate('load_more_products') + ' (' + productLoaded + ' / ' + total_element + ')</span><div class="see_all_product" onclick="seeXProduct(' + total_element + ', ' + page +',  1 )">' + Translator.translate('see_more_products') + '</div>';
                } else if (total_element >= nb_prod_default) {
                    this.innerHTML = '<div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ', 0)">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                    this.innerHTML = '<span class="nbProdDisplayed">' + Translator.translate('load_more_products') + ' (' + productLoaded + ' / ' + total_element + ')</span><div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ', 0, 0)">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}

function seeXProduct(nb_product, page) {

    $('.see_all_product').addClass('loading');
    $('.nbProdDisplayed').addClass('loading');

    var seeAllProduct = $('.see_all_product');
    var nbProdDisplayed = $('.nbProdDisplayed');
    var nbProdDisplayedLoader = $('.nbProdDisplayed + .loader');

    // we display a single loader when clicking on the “see all” button
    if (nbProdDisplayed.hasClass('loading') && seeAllProduct.hasClass('loading')) {
        nbProdDisplayed.hide();
        nbProdDisplayedLoader.hide();
    }

    var form = document.getElementById('filter_sticky');

    if (form != null) {
        var total_element = form.getValue('totalElems', parseFloat),
            nb_prod_default = form.getValue('nb_prod_default', parseFloat)
    }

    //Pour afficher tout, on repart de la page courante
    if (nb_product === total_element) {
        //On force à 11 pour conserver l'organisation de la page
        nb_product = nb_prod_default;
        $("#see_all").val("1");
        $("#nb_prod").val($("#totalElems").val());

        generateNewBlocProd(parseInt(page - 1), null, null, null, null, null, null, true);
    } else {
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);
        // Si on est sur la page de recherche
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(0);
        }
    }
}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String           type       [description]
 * @param  Number           idObj      [description]
 * @param  Number           page       [description]
 * @param  Number           nb_product [description]
 * @param  HTMLElement      btn        le bouton sur lequel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

function onColorCheckChange(evt) {
    if (this.checked && !evt.ctrlKey) { 
        $(this.closest(".selected_options"))
            .find("input[type=checkbox]:checked")
            .not(this)
            .prop("checked", false);
    }
}

//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, isFrom) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        nb_prod_default = nb_prod_default || form.getValue('nb_prod_default', parseFloat),
        output = {};

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky');

    if (page !== undefined && page !== 'all') {
        if (page != 0) {
            output.page = $('#list_item .item:not(".push")').length;   
            output.nb_specific_push = $('#push_rayon_1').length + $('#push_rayon_2').length;   
        }
    }

    if ($("#see_all").val() == 1) {
        output.page = 0;
    }

    if (page === 'all') {

        /** LEGACY **/

        var hash;

        if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0])) {

            var marginTop;
            if ($('.wrapper_bandeau')) {
                margintop = $('.wrapper_bandeau').height() + 20
            } else {
                margintop = $('#site_head_wrap').height() + 20
            }
            var $elem = hash ? $('#i=' + hash.replace('=', '')) : $('#list_item'),
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {

                $('#contents_wrapper').animate({
                    scrollTop: top
                }, 0.8);
            }, 0);
        }

        /** END LEGACY **/
    } else {

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + create_link('ajax_reload_products.php'),
            type: 'get',
            data: output,
            success: function (res) {
                var old_elm = document.getElementById('list_item'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');
                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                // This block of code updates the SEO-related link elements in the HTML document.
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='x-default']").attr("href", path_root + canonical);

                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".loader_scroll").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;

                    if (!fromScrollBottom && !fromScrollTop) {
                        $('#page').val(page + 1);
                    }

                    paginate(page + 1);

                    if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top - curOffset);

                    } else {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    if (typeof lazyloadImage === 'function') {
                        lazyloadImage();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) { }
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) { }
                    }

                    /** END LEGACY **/

                }

                /**
                 * Set double visus placement
                 */
                setVisuDoubleCol();
            },
            complete: function () {
                //force trigger on input hidden to fire the change() event
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');
                $(".autoLoad").fadeTo("fast", 0);

                if (!fromScrollBottom && !fromScrollTop && !isFrom) {

                    /**
                     * Lors de l'activation du voir tout
                     * il y a itinialement un nombre exact de produits
                     * quelque soit la page sur laquelle
                     * On vérifie donc que le scroll infini est actif ($('#see_all').val() == 1)
                     * ET qu'il n'y pas plus de X produits
                     * Ce qui signifie qu'on n'est pas en train d'exécuter le retour à un afficache par normal (ex : "Voir les X produits")
                     */

                    if ($('#see_all').val() == 1 && $('.item').length <= nb_product) {

                        /**
                         * Valeurs à adapter selon le template du site
                         */
                        window.scrollTo(0,
                            ($('#scroll_items').offset().top // Espace entre le haut de la page et le haut du block #scroll_items
                                + (($('#scroll_items').height() // Hauteur totale du block #scroll_items
                                    - $('.item').height()))) // Hauteur d'un produit
                        );

                    } else {

                        /**
                         * Lors de l'exécution de pagination normale (ex : clique sur la page 3)
                         * Ou du retour à un affichage normal (ex : "Voir les X produits") donc, que l'on annule le scroll infini
                         * On scroll en haut de la page
                         */

                        // Reset initialPage if from filter
                        $("#initial_page").trigger("change");
                    }
                } else {
                    //petit hack pour forcer l'activation du scroll infini => on scroll d'un pixel vers le haut une fois que l'appel Ajax est terminé
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: ($(window).scrollTop() + 1)
                        }, 200);
                    }, 250);
                }

                if ($('#see_all').val() == 1 && $('.item').length <= 21) {

                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: ($('#scroll_items').offset().top // Espace entre le haut de la page et le haut du block #scroll_items
                                + (($('#scroll_items').height() // Hauteur totale du block #scroll_items
                                    - ($('.item').height() * 2)))) // Hauteur d'un produit
                        }, 200);
                    }, 250);
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                }

                if (fromScrollBottom && !fromScrollTop) {
                    $("#initial_page").val(page + 1);
                }

                /**
                 * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                 */
                var productLoaded = $('.item:not(.push)').length;
                var productTotal = $('#totalElems').val();
                /***@ check if product loaded in the page is more then total elements */
                if (productLoaded >= productTotal) {

                    $('.nbProdDisplayed').hide();
                    $('.displayAllProd').html('<span class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ', 0)">' + Translator.translate('load_nbDefaultProd') + ' ' + nb_prod_default + ' ' + Translator.translate('label_produit') + '</span>');
                } else {

                    $('.nbProdDisplayed').show();
                    $('.nbProdDisplayed + .loader').show();
                    $('.nbProdDisplayed').html(Translator.translate('load_more_products') + ' (' + productLoaded + ' / ' + productTotal + ')');
                    $('.displayAllProd').html('<span class="see_all_product" onclick="seeXProduct(' + productTotal + ')">' + Translator.translate('see_all') + '</span>');
                }

                $('.nbProdDisplayed').removeClass('loading');
            }
        });
    }
}

function updateFilter(
    tab_response,
    page,
    parentPath,
    type,
    idObj,
    no_price_range_update
) {
    if (tab_response[0] != "") {
        var bloc_photo = tab_response[0];
        document.getElementById("list_item").innerHTML = bloc_photo;

        $(".slider_product_wrapper").each(function () {
            $(this).hover(
                function () {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .show();
                },
                function () {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .hide();
                }
            );
        });

        var nav = tab_response[2];
        var nav_bas = tab_response[3];

        var nb_prod = tab_response[4];

        if (document.getElementById("nb_prod_numeric"))
            document.getElementById("nb_prod_numeric").innerHTML = nb_prod;
        if (document.getElementById("label_nb_prod_numeric")) {
            if (nb_prod == "")
                document.getElementById("label_nb_prod_numeric").style.display =
                    "none";
            else
                document.getElementById("label_nb_prod_numeric").style.display =
                    "block";
        }

        if (typeof page == "undefined") {

            // Price range slider
            var prix_min = tab_response[5];
            var prix_max = tab_response[6];

            if (!no_price_range_update) {
                $("#amount1").val(prix_min);
                $("#amount2").val(prix_max);

                $("#slider-range").slider("option", {
                    min: parseInt(prix_min),
                    max: parseInt(prix_max),
                    values: [parseInt(prix_min), parseInt(prix_max)]
                });

                min_price = prix_min;
                max_price = prix_max;

                $(".ui-slider-range").width("100%");
                $(".ui-slider-range").css('left', '0%');
                $(".ui-slider-range").next().css('left', '0%');
                $(".ui-slider-range").next().next().css('left', '100%');
            }

            // couleur
            var color = tab_response[7];
            var tab_color_tmp = color.split("~");
            var cnt_color = tab_color_tmp.length;

            if (tab_color_tmp[0] != "") {
                txt_color = ajax_file(
                    parentPath + "ajax_sprintf.php?arg1=recherche_couleurs"
                );
                var bloc_color =
                    '<div class="select_recherche">' +
                    txt_color +
                    '</div><div class="selected_options select_colors"></div>';

                var str_color = "";

                for (var j = 0; j < cnt_color; j++) {
                    var detail_color = tab_color_tmp[j].split("|");
                    var class_color = "btn_select_off";

                    if (tab_color.length > 0) {
                        if (in_array_js(detail_color[0], tab_color)) {
                            class_color = "btn_select";
                        }
                    }
                    str_color +=
                        '<div id="couleur_' +
                        detail_color[0] +
                        '" class="left_menu_obj ' +
                        class_color +
                        " couleur_" +
                        detail_color[0] +
                        '"><div class="f_left couleur_check"></div><div class="f_left txt_menu">' +
                        detail_color[1] + "</div></div>";
                }

                $("#bloc_couleur")
                    .show()
                    .find(".selected_options")
                    .html(str_color);
            } else {
                $("#bloc_couleur")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
            // size
            var size = tab_response[8];

            var tab_size_tmp = size.split("~");
            var cnt_size = tab_size_tmp.length;

            if (tab_size_tmp[0] != "") {
                txt_size = ajax_file(
                    parentPath + "ajax_sprintf.php?arg1=recherche_taille"
                );

                var bloc_size =
                    '<div class="select_recherche">' +
                    txt_size +
                    '</div><div class="selected_options"></div>';

                var str_size = "";

                for (var j = 0; j < cnt_size; j++) {
                    var detail_size = tab_size_tmp[j].split("|");
                    var class_size = "btn_select_off";

                    if (tab_taille.length > 0) {
                        if (in_array_js(detail_size[0], tab_taille))
                            class_size = "btn_select";
                    }

                    str_size +=
                        '<div id="taille_' +
                        detail_size[0] +
                        '" class="left_menu_obj ' +
                        class_size +
                        '"><div class="f_left"></div><div class="f_left txt_menu">' +
                        detail_size[1] +
                        "</div></div>";
                }

                $("#bloc_taille")
                    .show()
                    .find(".selected_options")
                    .html(str_size);
                var windowW = $(window).height();
                if (windowW >= 900) {
                    $("#choix_recherche").sideBarEase("refresh");
                }
            } else {
                $("#bloc_taille")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
        }
    } else {
        $("#list_item").html(
            ajax_file(parentPath + "ajax_sprintf.php?arg1=no_response_search")
        );
        $("#pagination_haut").html("");
        $("#pagination_bas").html("");
    }
    $(".left_menu_obj").on("click touchend", filterBoxesToggle);
    var windowW = $(window).height();
    if (windowW >= 900) {
        $("#choix_recherche").sideBarEase("refresh");
    }
    $("#page").val(0);
}

//On va regarde tout les bloc et on envoi l'ajax
//Pour les blocs de cases à cocher
//Pour le slider des prix
//Pour le bouton qui supprime un element
var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById("filter_sticky"),
        output = {},
        is_from_page = false,
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function (elm) {
        if (elm.type === "checkbox" || elm.type === "radio") {
            if (elm.checked && elm.value !== "") {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === "hidden") {
            if (elm.value !== "") {
                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join("~");
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== "all") {
        form.page.value = page * nb_product;
        is_from_page = true;

    }

    // Add misc values to output object
    output.page = form.page.value;
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;
    output.keyword = form.keyword.value;
    output.nb_prod = form.nb_prod.value;
    output.nb_prod_default = form.nb_prod_default.value;
    output.totalElems = form.totalElems.value;

    // Si le prix existe
    if ($("#price_min").length > 0) {
        // Récupération du prix
        output.prix =
            form.getValue("price_min", parseFloat) +
            "~" +
            form.getValue("price_max", parseFloat);
    }

    if (bloc_search_xhr) {
        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url: path_relative_root + create_link("ajax_reload_search.php"),
        type: "get",
        data: output,
        success: function (res) {
            var old_elm = document.getElementById("scroll_items"),
                content,
                itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                btn.nextElementSibling.classList.contains("btn_tunnel")
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split("~");

            itm_length = parseFloat(res[1]);

            if (content !== "") {

                if (!isNaN(page)) {
                    old_elm.setAttribute("data-p", page * nb_product);
                }

                old_elm.innerHTML = "";

                document.getElementById("nb_articles").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);

                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }

                /** LEGACY **/

                var js_compteur = $(".vf_countdown").length;
                var js_encart = content.match(
                    /\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g
                );

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(
                                js_compteur[i].replace(
                                    /(vf_compteur_begin|vf_compteur_end)/g,
                                    ""
                                )
                            );
                        }
                    } catch (e) { }
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(
                            /(\/\/vf_begin|\/\/vf_end)/g,
                            ""
                        );
                        eval(js_encart);
                    } catch (e) { }
                }

                /** END LEGACY **/
            }
        },
        complete: function () {
            //force trigger on input hidden to fire the change() event
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');
            if (!fromScrollBottom && !fromScrollTop) {
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 200);
                }, 250);
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
            }
        },
    });
}

/**
 * ZOOM PRODUCT PAGE
 * @param slide
 */
function zoomImg(slide) {

    window.setTimeout(function () {

        var zoom_box_details = new Swiper('#zoom_box_details', {

            direction: 'vertical',
            slidesPerView: 10,
            preloadImages: false,
            lazy: true,
            watchSlidesProgress: true,
        });

        var zoom_box_wrapper = new Swiper('#zoom_box_wrapper', {

            slidesPerView: 1,
            preloadImages: false,
            lazy: true,
            thumbs: {
                swiper: zoom_box_details,
            },
        });

        if (slide) {

            if (slide === 1) {

                zoom_box_wrapper.slideTo(0);
            } else {

                zoom_box_wrapper.slideTo(slide -= 1);
            }
        }
        else {
            zoom_box_wrapper.slideTo(0);
        }
    }, 150);

    openMultiShad('zoomBox');
}

function updateSwiperSlide() {
    // Find all swiper elements on the pages to run swiper initialisation when needed
    if ($(".swiper-container")) {
        var elements = $('[class*="swiper-container"]');
        // Iterate through each element
        elements.each(function() {
            // Check if the element has a Swiper instance
            if (this.swiper) {
                // Update the Swiper instance
                this.swiper.update();
                this.swiper.updateSlides();
            }
        });
    }
}

updateSwiperSlide();

if ($(".swiper-container")) {
    var actionTimeout;
}

// Add a timeout so that it delay the constant action of scroll or resize
function debounce(func, delay) {
    clearTimeout(actionTimeout);
    actionTimeout = setTimeout(func, delay);
}

// Added updateSwiperSlide twice so that if on the first time
// It did not execute due to dom not ready it can on the second time
function handleActionEnd() {
    updateSwiperSlide();

    setTimeout( function () {
        updateSwiperSlide();
    }, 100);
}

if ($(".swiper-container")) {
    window.addEventListener('resize', function() {
    debounce(handleActionEnd, 200);
    });

    window.addEventListener('scroll', function() {
        clearTimeout( actionTimeout );
        actionTimeout = setTimeout(handleActionEnd, 200);
    });
}

/**
 * Reload product from ref
 */
function loadProduct( new_id, type, page, itm_rr_id) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: (page !== undefined && page !== '') ? page : 'product',
        is_achat_express: is_wishlist ? 1 : 0,
        is_achat_express_v2: is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod: cpt_prod - 1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_product_regroup_ref.php'),
        data: data,
        success: function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;

                if (data.type_page == 'product') {

                    var $html_main = $(stripCombo(datas.html_main));

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);
                    var association_wrapper = $('.product_associations_wrapper', $html_main);
                    var ranges_wrapper = $('.product_ranges_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);

                        var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                        var margin_right = $('#itm-' + new_itm_id).next().css('margin-right');

                        if ($(".homepage").length > 0 || $(".satellite_store_details").length > 0) {
                            save_description = $('#itm-' + new_itm_id + ' .wrapper_description').html();
                            $('#itm-' + new_itm_id + ' .wrapper_description').html('<div class="name">' + save_description + '</div>');
                            if ($(".satellite_store_details").length > 0) {
                                $('#itm-' + new_itm_id).addClass('swiper-slide');
                                $('#itm-' + new_itm_id).css('margin-right', margin_right);
                            }
                        } else if ($(".product_page").length > 0 || $(".rayon_lookbook").length > 0) {
                            if ($(".product_page").length > 0) {
                                $('#itm-' + new_itm_id).addClass('assoc');
                                $('#itm-' + new_itm_id).css('margin-right', margin_right);
                            } else if ($(".rayon_lookbook").length > 0) {
                                $('#itm-' + new_itm_id + ' .imgWrapper').addClass('imgWrapperinlook');
                            }
                        }
                        var model;
                        if ($('#itm-' + new_itm_id).next('.item.swiper-slide').length) {
                            model = $('#itm-' + new_itm_id).next('.item.swiper-slide');
                        } else {
                            model = $('#itm-' + new_itm_id).prev('.item.swiper-slide');
                        }
                        var margin_right = $(model).css('margin-right'),
                            width = $(model).css('width');

                        $('#itm-' + new_itm_id).css({
                            marginRight: margin_right
                        });

                        
                        // Update swiper initialisation for the new slide created
                        updateSwiperSlide();

                        // scroll by 1px after slider has been changed when color has change to reinitial swiper
                        // Get the current scroll position
                        var currentPosition = $(window).scrollTop();
                        // Scroll the page by 1 pixel from the current position so as to run function updateSwiperSlide and reinitialise the swiper with new slider
                        $('html, body').animate({ scrollTop: currentPosition + 1 }, 0);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else if (is_wishlist) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                    } else {
                        var $html_middle = $(stripCombo(datas.html_middle));

                        var color = $("#ligne_couleur").html();

                        var taille = $("#ligne_pointure").html();

                        var contents = $('<div />').html($html_main);

                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());
                        // Association wrapper
                        $('.product_associations_wrapper', '#site_global_wrap').html(association_wrapper.html());
                        // Ranges wrapper
                        $('.product_ranges_wrapper', '#site_global_wrap').html(ranges_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        var video = $('.wrapper_video', contents);

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        // On scroll en haut de la page dans le cas d'un regroupement
                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }

                    if ($("#buyNowSwiper").length > 0) {
                        var buyNowSwiper = document.getElementById("buyNowSwiper");

                        if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {

                            buyNowSwiper = new Swiper("#buyNowSwiper", {
                                sliderPerView: 1,
                                spaceBetween: 50,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }
                    }
                } else if (type_page == 'look') {
                    var $html_main = $(stripCombo(datas.html_main));

                    var $html_middle = $(stripCombo(datas.html_middle));

                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);

                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    var contents = $('<div />').html($html_main);

                    // Photo et information
                    $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                    // Description compo, livraison, guide tailles
                    $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');
                    $('#itm_thumb_list').empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList(thumbnails);

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {

                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);

                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

            } else {
                $('#btn_error').fadeIn();
            }

            $('#list_item .item:nth-child(22n+11), #list_item .item:nth-child(22n+20)').each(function () {

                $(this).addClass('doubleColItem');
            });

            $('#list_item .item.doubleColItem .imgWrapper .productSwiper').each(function () {

                $(this).addClass('doubleCol');
            });
        },
        complete: function () {
            var heads = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function (event) {
                event.preventDefault();

                var element = $(this);
                var tab_number = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {

                var main_productVisu = new Swiper('#zoom_box_wrapper', {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.zoomNext',
                        prevEl: '.zoomPrev',
                    },
                    preloadImages: false,
                    lazy: true
                });

                function updateSrc(path_web, dest) {
                    var tmp_string_large = document.getElementById("img_large").src;
                    tmp_string = tmp_string_large.replace(/medium/, "large");

                    document
                        .getElementById("img_large")
                        .setAttribute(
                            "onclick",
                            "zoomImg('" + tmp_string + "','" + path_web + "')"
                        );
                    document.getElementById("loupe").href =
                        "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
                    $(".zoomLens").css({ backgroundImage: 'url("' + tmp_string_large + '")' });

                    if (dest == "vue") {
                        var i = 0;
                        var trouve = false;
                        while (i < tab_js.length && !trouve) {
                            trouve =
                                tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                                tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
                            i++;
                        }

                        cpt_img = i - 1;
                    }
                }

                if (is_wishlist) {
                    setTimeout(function () {

                        var expressLider = new Swiper('#expressLider', {

                            slidesPerView: 1,
                            preloadImages: false,
                            lazy: true,
                            navigation: {
                                prevEl: '.expressLiderPrev',
                                nextEl: '.expressLiderNext',
                            },
                        });
                    }, 500);
                }

                /* Fin reinitialisation sliders visus */

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                    
                    // Dont call the function if we are on products landing page (rayon)
                    if (!$('body.category').length) {
                        initAlmaWidget();
                    }

                    // Reset cross-sell swipers after color change on main product only
                    if ($('body.product_page').length && !data.is_achat_express_v2) {
                        setUpsellFicheProd();
                    }
                }, 500);

                // Update color name besides COLOR label
                var currentSelectedColor = $('#currentSelectedColor');
                var couleurProd = $('#fpRightPart .prodColor:checked');

                currentSelectedColor.html(couleurProd.data('nom'));
            }, 250);

            heads.each(function () {
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

            if (is_assoc_product) {
                from_page = 'assoc';
            } else {
                from_page = 'product';
            }

            getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd, from_page, data.couleurProd, itm_rr_id, is_wishlist);

            if (!data.is_achat_express_v2) {
                checkProductInWishlist(data.produit_id_new);
                $("#addToWishlistButton").click(addToWishlist);
            }

            //Simulates scroll (2px) to load images
            window.scrollTo(0, $(window).scrollTop() + 2);

            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    'event': 'view_item',
                });
            }
            
            if ($(".productVisualMulti").length>0) {

                $(".productVisualMulti").each(function (index) {
                    $(this).addClass("prodSwiper-" + index);
                    $(this).find(".rollover_rayonsNext").addClass("btn-next-" + index);
                    $(this).find(".rollover_rayonsPrev").addClass("btn-prev-" + index);

                    // Normal product slider
                    if (!$(this).hasClass('doubleCol')) {
                        new Swiper(".prodSwiper-" + index, {
                            slidesPerView: 1,
                            observer: true,
                            observeParents: true,
                            navigation: {
                                nextEl: ".btn-next-" + index,
                                prevEl: ".btn-prev-" + index,
                            },
                            preloadImages: false,
                            lazy: true,
                            loadPrevNext: true,
                            loadOnTransitionStart: true
                        });
                        
                        // Product slider that fits double columns
                    } else if ($(this).hasClass('doubleCol')) {
                        new Swiper(".prodSwiper-" + index, {
                            slidesPerView: 2,
                            observer: true,
                            observeParents: true,
                            navigation: {
                                nextEl: ".btn-next-" + index,
                                prevEl: ".btn-prev-" + index,
                            },
                            preloadImages: false,
                            lazy: true,
                            loadPrevNext: true,
                            loadOnTransitionStart: true
                        });
                    }
                });
            }
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;

    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }

    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        itm_rr_id = itm_rr_id !== '' ? '_' + itm_rr_id.split('_')[1] : '';
        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', '', itm_rr_id);
        } else {
            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express_v2) {
                checkProductInWishlist();
            }

            var img = $(this).data('pic');

            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var nbSize = form.getValue('cnt_sizes');
    //mise à jour du DataLayer
    if (window.dataLayer !== undefined && nbSize !== undefined && nbSize > 1) {
        window.dataLayer.push({
            'event': 'changeSize',
            'produit': {
                'size_value': ($(this).attr('data-nom')),
            }
        });
        if (window.dataLayer[0].product) {
            window.dataLayer[0].product.size = ($(this).attr('data-nom'));
        }
    }

    var control;
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        var id = form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();
        // En fonction de la couleur on regarde si le produit est disponible
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id, is_wishlist,this);
        // On regarde si le produti est dejà dans la wishlist
        if (!is_achat_express_v2) {
            checkProductInWishlist(id);
        }
    }
}

/**
 * Retrieves the available quantity of a product based on its ID, quantity, color, and size.
 * 
 * @param {string} id - The ID of the product.
 * @param {number} qty - The quantity of the product.
 * @param {string} from - The source of the request (e.g., 'basket').
 * @param {string} event - The event triggering the request.
 * @param {string} itm_rr_id - The ID of the item recommendation.
 * @param {boolean} is_wishlist - Indicates whether the product is in the wishlist.
 * @param {HTMLElement} currentElem - The current element triggering the request.
 */
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist,currentElem) {
    let visitedArticles = (currentElem && currentElem.classList && currentElem.classList.contains("visited_articles_size")) ? "#gondole_derniers_articles_vus " : "";
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.querySelector(visitedArticles + '#prod_info_' + id + itm_rr_id);
    var color_id = $(visitedArticles + '#couleurProd' + elem).val();
    var size_id = $(visitedArticles + '#tailleProd' + elem).val();
    var plnb = $(visitedArticles + '#produit_lot_nb' + elem).val();

    // If we don't have a selected size or color, then we don't perform any verification.
    if (color_id == '' || size_id == '') {
        return;
    }

    var data = {
        idprod: $(visitedArticles + '#produit_id' + elem).val(),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {
        data.suff = $(visitedArticles + '#produit_id' + elem).val();
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false,
        dataType: 'json',
        data: data,
        success: function (res) {

            var stockQuantity = res.inventory.stockQuantity;

            if (stockQuantity == 0) {

                $(".eclat_product_nouveaute_fiche_produit").show();
                $(".bloc_add_alert" + elem).show();
                $("#prod_info" + elem + " .bloc_add_alert_form").show();
                $("#prod_info" + elem + " .rollover_left").hide();

                if (!is_achat_express) {
                    $("#bloc_add_basket").hide();
                }

                $('#ligne_qte').append($('.restock_date'));
                $('.taunt_text').css('display', 'none');

                /**
                * Alert stock FP change placeholder
                */
                if ($('.expressAlertStock').length) {
                    // Alert stock FP change placeholder
                    var alertPlaceholder = $('.expressAlertStock .w-input-element');
                    alertPlaceholder.attr('placeholder', Translator.translate('your_email'));
                    // Alert stock express buy change placeholder
                    var expressAlertPlaceholder = $('.expressAlertStock button span');
                    expressAlertPlaceholder.html('OK');
                }

                // When refreshing, call checkSubmitState if cnt_size == 1
                if ($('#ligne_pointure .choices_list')) {
                    setTimeout(() => {
                        if ($('#ligne_pointure .choices_list .form_itm.check.size').length == 1) {
                            checkSubmitState();
                        }
                    }, 500);
                }

                if (is_achat_express) {
                    $("#bloc_size_not_available" + elem).show();
                } else {
                    $(".product_main_wrapper #size_is_not_available" ).show();
                }

            } else {

                if (is_achat_express) {
                    $("#bloc_size_not_available" + elem).hide();
                    
                } else {
                    $(".product_main_wrapper #size_is_not_available").hide();
                }

                if (stockQuantity > 0 && stockQuantity <= 3) {
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                if (!is_achat_express) {
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $(".bloc_add_alert").hide();
                    $("#bloc_add_basket").show();
                }
            }
            // DATALAYER V2
            pushIntoDatalayer(res.dataLayer_object);
        }
    });
}

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();
    $("#prod_info_" + elem + " .bloc_add_color").show();
    $("#prod_info_" + elem + " .bloc_add_color").removeClass('loading');

    // Show bloc colors and size
    $('#ligne_pointure_' + elem).show();
    $('#ligne_couleur_' + elem).show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
}

/**
 * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
 */
function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {

            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {

            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (iserrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {

        $('.bloc_add_alert_form').slideDown('fast');
    }
}

/*
 * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function () {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function sortTrigger() {
    var sortTrigger = $('#sort_Trigger');
    var trigger = $('#trigger_filtre');

    if (sortTrigger.hasClass('triggered')) {

        sortTrigger.removeClass('triggered');
    } else {

        sortTrigger.addClass('triggered');
        if (trigger.hasClass('triggered')) {

            trigger.removeClass('triggered');
        }
    }
}

function filterTrigger() {

    var sortTrigger = $('#sort_Trigger');
    var trigger = $('#trigger_filtre');

    if (!trigger.hasClass('triggered')) {

        if (sortTrigger.hasClass('triggered')) {

            sortTrigger.removeClass('triggered');
        }
        trigger.addClass('triggered');
    } else if (trigger.hasClass('triggered')) {

        closeFiltre();
    }

    function closeFiltre() {

        if (trigger.hasClass('triggered')) {

            trigger.removeClass('triggered');
        }
    }
}

var tempo_reset = false;
var price_min = $("#price_min").val();
var price_max = $("#price_max").val();
function initAllFilters() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();
                elm.value = 0;
                elm.checked = false;
            }
        }
    });

    var min_price = $('input[name="initial_min_price"]').val();
    var max_price = $('input[name="initial_max_price"]').val();

    $(".slider-range-container .min").html(min_price);
    $(".slider-range-container .max").html(max_price);

    $(".min_input").html(min_price);
    $(".max_input").html(max_price);

    $('.slider-range div.ui-slider-range').css('left', '0');
    $('.slider-range div.ui-slider-range').css('width', '100%');
    $('.slider-range-container span.min').html($('[data-min]').data('min'));
    $('.slider-range-container span.max').html($('[data-max]').data('max'));

    // Initialisation du slider de prix
    initPriceSlider('.slider-range.prix', true);
    $('#trigger_filtre').removeClass('triggered');

    $('a.tri_price.actif').removeClass('actif');
    $('input#choice_tri').val(0);

    tempo_reset = true;
    generateNewBlocProd.call(form, 0);
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    var produit_id = $('#produit_principal').val();
    var data = {produit_id: new Array(produit_id)};

    data.warehouse_only = isGlobalEActive ? 1 : 0;

    var elem_id = $(this).val();

    $.ajax({
        url: path_relative_root + create_link('ajax_get_colisages_states_by_color_size'),
        type: 'post',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (res.success && res.result) {
                var sizes_tab = res.result[produit_id][elem_id];
                var size_box, is_disabled;
                var has_stock = false;

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                            '[value="' + size_id + '"]'
                        );
                        is_disabled = !sizes_tab[size_id];
                        if (is_disabled) {
                            size_box.addClass('disabled');
                        } else {
                            size_box.removeClass('disabled');
                            has_stock = true;
                        }
                    }
                }

                if (has_stock) {
                    $("#product_addcart .prod_btn_wrapper").fadeIn(500);
                    $("#bloc_add_alert").hide();
                    $("#size_is_not_available" ).hide();
                    
                } else {
                    $("#product_addcart .prod_btn_wrapper").hide();
                    $("#bloc_add_alert").show();
                    $("#size_is_not_available").show();
                }
            }
        }
    });
}

/**
 * Price range slider
 */
function initPriceSlider(slider_name, isReinit) {
    // Le slider pour le prix
    var slider = $(slider_name);
    var min = slider.data('min');
    var max = slider.data('max');

    var min_selected, max_selected;

    if (isReinit == true) {
        min_selected = min;
        max_selected = max;
    } else {
        min_selected = $(".min_input", slider.parent()).val();
        max_selected = $(".max_input", slider.parent()).val();
    }

    slider.slider({
        range: true,
        min: min,
        max: max,
        values: [
            min_selected,
            max_selected
        ],
        slide: function (event, ui) {
            if (ui.handleIndex === 0) {
                $(".min", $(this).parent()).html(ui.value);
                $(".min_input", $(this).parent())[0].value = ui.value;
            } else {
                $(".max", $(this).parent()).html(ui.value);
                $(".max_input", $(this).parent())[0].value = ui.value;
            }
        },
        stop: function (event, ui) {
            generateNewBlocProd.call(document.getElementById('filter_sticky'), 0)
        }
    });
}

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function () {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    const swiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        // spaceBetween: 1,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: true,
    });
}

/**
 * Affiche la liste des quantites d'un produit (HTML Tag select)
 */
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                parentPath +
                'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
            "ajax_liste_qte.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            couleurs +
            "&idtaille=" +
            tailles +
            "&produit_lot_nb=" +
            $("#produit_lot_nb").val() +
            "&suff=" +
            (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.parent().addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

    var id = '';

    var prix_id = 'prixU';

    if (itm_rr_id === undefined) {
        itm_rr_id = '';
    }

    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void (s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void (s.t());
        }

        // DATALAYER V2
        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];
            if (is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);
            }
            pushIntoDatalayer(dl_objet);
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response_basket);

        $("#lightbox_achat_express, #shad").removeClass('actif');

        newshowmodal(produit_id, is_not_produit);
        mReloaded();
        
    } else {

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    initScrollCartList();
    initSwiperHoverPanier();

    if ($('.smallbasketExpress').hasClass('actif')) {
        $('.smallbasketExpress').removeClass('actif')
    }

    return false;
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = $("#couleurProd" + i);
    var tailles = $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_couleur");
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_quantity");
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();

        if (tailles && tailles.val() != "none") idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(
            path_web +
            "ajax_upd_panier.php?prixu=" +
            prixU +
            "&idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&qte=" +
            qte +
            "&panierId=" +
            paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(Translator.translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

function newshowmodal(products, type, root, id_produit, is_not_produit) {

    var show_top_cart = document.getElementById('show_top_cart');

    show_top_cart.parentElement.classList.add('hasItem');
    show_top_cart.classList.add('appear');

    setTimeout(function () {

        header.classList.add('shaded');
        shad_header.classList.add('actif');
    }, 250);

    window.setTimeout(function () {

        show_top_cart.classList.remove('appear');
        header.classList.remove('shaded');
        shad_header.classList.remove('actif');
    }, 2000);
}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {

    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte
        },
        url: path_relative_root + create_link('ajax_removeBasketProd.php'),
        success: function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            $('#cart_top').html(res.datas.html);
            $('#cart_top #show_top_cart').addClass('appear');

            if ($('#show_top_cart').hasClass('hide')) {
                topCartDesappear();
            }
            initScrollCartList();
            initSwiperHoverPanier();
        }
    });
}

/**
Add to cart all products from header rollover wishlist
*/
$(document).on("click","#btn_top_add_wishlist", function (e) {
    e.preventDefault();

    let datas = [];
    let selectedTopProducts = {};

    $(".remove_prod.wishlist_remove").each(function() {

        datas.push({
            productId: $(this).attr("data-produit-id"),
            colorId: $(this).attr("data-color-id"),
            sizeId: $(this).attr("data-size-id"),
        });
    });

    selectedTopProducts.selectedProducts = JSON.stringify(datas);
    moveProductFromWishlistToCart(false, selectedTopProducts);
});

/**
Add to cart the selected product from header rollover wishlist
*/
$("#top_wish_to_cart").on("click", function (e) {

    e.preventDefault();
    selectedProduct = $(this).attr("data-id");

    $.post(path_relative_root + create_link("ajax_add_panier_wishlist.php"), { data: selectedProduct }, function (resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
});

/**
Add to wishlist the selected product from header rollover basket
*/
function topBasketToWish(product) {

    var productId = $(product).attr("data-productId");
    var sizeId = $(product).attr("data-sizeId");
    var colorId = $(product).attr("data-colorId");

    var data = {
        product_id: productId,
        size_id: sizeId,
        color_id: colorId,
    };

    if (!checkProductInWishlist(productId)) {

        $.post(path_relative_root + create_link('ajax_add_to_wishlist.php') + '?time=' + Date.now(), data, function (resp) {

            if (resp.error) {
                if (resp.message == "login_required") {
                    var html =
                        '<div onclick="closeMultiShad(\'abox\')" class="closeBtn"></div><div class="box_wishlist">' +
                        resp.result.error_message +
                        '<div class="btn_container">' +
                        '<a class="button primary" href="#" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</a>" +
                        "</div>" +
                        "</div>";

                    if ($('#abox').length) {
                        // If abox exists, injected newly created html inside
                        $('#abox').addClass('wishConnection');
                        $('#abox').html(html);
                    } else {
                        // If it does not exist, create it
                        var abox = '<div id="abox" class="lightbox wishConnection actif"></div>';
                        $('#appContainer').append(abox);
                        $(abox).html(html);
                    }
                    openMultiShad('abox');
                } else {
                    alert(Translator.translate(resp.result.error_message));
                    return false;
                }
            } else {
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'addToWishlist',
                    });
                }
                var response_basket = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
                $("#wishlist_top").html(response_basket);
                $("#cart_top").removeClass('hover');
                $("#wishlist_top").addClass('hover');
                addToWishlistUpdateHeader(resp.result.wishlist_size);
                initSwiperTunnel();
            }
        });

    } else {
        alert(Translator.translate('err_product_exist_in_wishlist'));
    }
}
function openSmallBasketExpress(id) {

    var show_top_cart = document.getElementById('show_top_cart');
    show_top_cart.parentElement.classList.add('hasItem');
    show_top_cart.classList.add('appear');

    $("#show_top_cart").addClass("noDisplay");
    $(".smallbasketExpress").removeClass("actif");

    setTimeout(function () {
        $("#" + id).addClass("actif");
        header.classList.add('shaded');
        shad_header.classList.add('actif');
    }, 300);
}

function closeSmallBasketExpress(id) {
    var show_top_cart = document.getElementById('show_top_cart');

    show_top_cart.parentElement.classList.add('hasItem');
    show_top_cart.classList.add('appear');
    $("#" + id).removeClass("actif");
    header.classList.add('shaded');
    shad_header.classList.add('actif');
    $("#show_top_cart").removeClass("noDisplay");
}

/**
 *
 */
function topWishToBasket(array_wishlist_product_ids) {

    var id = $(this).attr("data-id");
    var current_elem = $(this);

    $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), { data: array_wishlist_product_ids.join(",") }, function (resp) {

        if (resp == "ok") {

            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

/**
 *
 * @param {id produit} product_id
 * @param {id regroup ref} regroup_ref_id
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);
    btn.parent('.form_submit').addClass('loading');
    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;
    var form = document.getElementById('prod_info' + elem);
    var color_id = form.elements['couleurProd'].value;
    var size_id = form.elements['tailleProd'].value;
    var tailleProdCrossSell = $('#qteProd_' + product_id + regroup_ref_id);
    var sizeQnt = colorQnt = 0;
    if (colorQnt > 1) {
        $(".form_itm.color input[name='itm_color']").each(function (index) {
            $(this).prop("checked", false);
        });
        $("#ligne_couleur_" + product_id + regroup_ref_id).fadeIn(600);
    } else if (colorQnt < 2 && sizeQnt > 1) {

        $("#ligne_pointure_" + product_id + regroup_ref_id).fadeIn(600);
    } else {

        if (($('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) || ($('#tailleProd_' + product_id + '_' + regroup_ref_id).value == '0') || (typeof tailleProdCrossSell[0] != "undefined" && tailleProdCrossSell[0].value == "0")) {

            $(this).parent().hide();
            $('#bloc_add_alert' + elem).show();
            $("#prod_info" + elem + " .rollover_left").hide();
        } else {

            if (color_id !== '' && size_id !== '') {

                waitingAddToBasket(event, form, '', regroup_ref_id);
                setTimeout(function () {
                    btn.parent('.form_submit').removeClass('loading');
                }, 2000);
            } else if (color_id === '' && size_id === '') {

                alert(Translator.translate('choose_a_size_and_a_color'));
                btn.parent('.form_submit').removeClass('loading');
            } else if (color_id === '') {

                alert(Translator.translate('choose_a_color'));
                btn.parent('.form_submit').removeClass('loading');
            } else if (size_id === '') {

                alert(Translator.translate('choose_a_size'));
                btn.parent('.form_submit').removeClass('loading');
            }
        }
    }
}

/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');

        subLogin.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/*********/

function afficheLostPasswordTop() {
    if ($("#bloc_erreur").is(":visible")) {
        $("#bloc_erreur").css("display", "none");
    }

    $("#is_client_form").hide();
    $("#lost_password_top")
        .css({ display: "block", opacity: 0 })
        .fadeIn(600);
}

function reloadBlocLoginTop() {
    if ($("#bloc_erreur").is(":visible")) {
        $("#bloc_erreur").css("display", "none");
    }

    $("#lost_password_top").hide();
    $("#returning_customer_top").fadeIn(600);
}

// Alters item quantity in cart
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link('order_basket'),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {
                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                var cart = Translator.translate('mon_panier');
                var wishlist = Translator.translate('la_wishlist');
                var total = $('#cart_top .cart').attr('data-cart');
                var totalWish = $('#wishlist_top .cart').attr('data-cart');
                var text;
                var textWish;

                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                };

                if (totalWish <= 1) {
                    textWish = Translator.translate('one_product');
                } else {
                    textWish = Translator.translate('2_product');
                };

                $('.cart_main_title.basket').html(cart + ' <span class="nb_products">(' + total + ' ' + text + ')</span>');
                $('.cart_main_title.wishlist').html(wishlist + ' <span class="nb_products">(' + totalWish + ' ' + textWish + ')</span>');

                initSwiperTunnel();
                initSwiperHoverPanier();

                var amount = $('#montant_txt').html();
                amount = amount.replaceAll(/[^0-9]/g, '');

                updateCartElements();
            },
            complete : function() {
                getWishlistProducts();
            }
        });
    }
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    var container = document.getElementById(elementID);

    //clearTimeout(time_out);
    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
            return true;
        } else {
            container.innerHTML = '';
            return false;
        }
    } else
        return false;
}

/**
 * Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
 */
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr("id"),
        mId = id.substring(id.indexOf("_") + 1),
        messageContainer = $(".message_account_container");

    if (messageContainer.hasClass("appear")) {
        messageContainer.removeClass("appear");
    } else {
        messageContainer.addClass("appear");
    }

    if (!elem.hasClass("actif")) {
        $(".message_detail").slideUp();
        $(".btn_ouvrir_message")
            .not(elem)
            .removeClass("actif");
        $(".btn_ouvrir_message")
            .not(elem)
            .text(msg_open);

        if ($("#msg_txt_" + mId).html() == "") {
            $.ajax({
                type: "post",
                data: { mId: mId, type: 1 },
                url: path_relative_root + create_link("message_detail"),
                success: function (response) {
                    $("#msg_txt_" + mId).html(response);
                    $("#msg_" + mId).slideDown();
                },
            });
        } else $("#msg_" + mId).slideDown();
        elem.addClass("actif");
        elem.text(msg_close);
    } else {
        $(".message_detail").slideUp();
        elem.removeClass("actif");
        elem.text(msg_open);
    }
}

function show_message(mId, hide) {
    if (hide == 0) {
        var ajax_content = ajax_file("ajax_show_messages.php?mId=" + mId);
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = "";
            document.getElementById("message_" + mId).style.cursor = "default";
        }
    } else {
        var ajax_content = ajax_file(
            "ajax_show_messages.php?mId=" + mId + "&hide=1"
        );
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick =
                "show_message(" + mId + ",0);";

            document.getElementById("message_" + mId).style.cursor = "pointer";
        }
    }
}

var global_id_transporteur = '';
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    var divId = 'popup_numero_retour';
    divId += '_' + (multiTransp ? idOrderTransp : idOrder);
    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    openMultiShad(divId);

    setTimeout(function () {

        $('.returnLIghtboxWrapper').overlayScrollbars({
            overflowBehavior: {
                x: "hidden",
                y: "scroll",
            },

            scrollbars: {
                // autoHide: "leave",
                // autoHideDelay: 500,
            }
        });
    }, 500);
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp, parentPath) {

    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour + '&choix=no_impression';
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    }

    if (!parentPath) {
        parentPath = path_relative_root;
    }

    $.ajax({
        url: parentPath + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_return_label '));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.open(res);
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, multiTransp) {

    var has_errors = false;
    $(".return_quantity").each(function() {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0  && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form_' + idOrder).serialize();
        data += '&idOrder='+idOrder+'&multiTransp='+multiTransp;

        $.ajax({
            url : path_relative_root + 'ajax_create_etiquette.php',
            type : 'post',
            data : data,
            success : function (res) {
                location.reload();
            }
        });
    }
}

function initScrollCartList() {
    let cartList = document.getElementById('cart_list');
    let screenHeight = window.innerHeight;

    if (cartList) {
            if (cartList.childElementCount > 2) {
        $('#cart_list').addClass('scroll');
    }

    else if (cartList.childElementCount > 2 && screenHeight < 901) {
        $('#cart_list').addClass('scroll');
    }
    } else {
        return
    }
}

$(function () {

    initSwiperHoverPanier();
    initSwiperTunnel();
    initScrollCartList();

    /**
     * PRODUCT PAGE BACK BUTTON SCROLL TO DESIRED ITEM
     */
    if ($("#scroll_items").length > 0) {
        var element_id = document.location.hash.substr(3);

        if (element_id !== "" && typeof element_id !== 'undefined') {
            $("html, body").animate(
                {
                    scrollTop: $("#itm-" + element_id).offset().top - 120, // 120 hauteur du menu en scroll
                },
                500
            );
        }
    }

    /**
     * CART STEP_1 PAGE : CHANGE WORDING IN PROMO INPUT
     * This method avoids a useless override.
     */

    if ($('body.cart.step_1').length) {
        $('body.cart.step_1').find('#formPromo p.w-input-label span').text('Code promo');
    }

    var blockCB = $('.wrapper_bloc_banque.credit_card');
    var blockPayPal = $('.wrapper_bloc_banque.paypal');
    var blockBancontact = $('.wrapper_bloc_banque.bancontact');
    var formCB = $('.wrapper_bloc_banque.credit_card #hipay-3-form');

    if (blockCB.hasClass('actif')) {
    }

    blockCB.on("click", function () {
        $(this).addClass('actif');
        blockPayPal.removeClass('actif');
        blockBancontact.removeClass('actif');
        formCB.slideDown('slow');
    });

    blockPayPal.on("click", function () {
        $(this).addClass('actif');
        blockCB.removeClass('actif');
        blockBancontact.removeClass('actif');
        formCB.slideUp('slow');
    });

    blockBancontact.on("click", function () {
        $(this).addClass('actif');
        blockCB.removeClass('actif');
        blockBancontact.removeClass('actif');
        formCB.slideUp('slow');
    })

    $(document).scroll(function () {
        var getDistance = $(document).scrollLeft();
        $('body.category #site_head_wrap').css("left", -getDistance);
    });

    /**
     * Add body class for Lookbook
     */
    if ($('.rayon_lookbook').length) {

        $('body').addClass('lookBook');
    }

    $(".content_conseil .bloc_ctn .accordion_title").each(function () {
        $(this).on("click", function () {
            if (!$(this).next().is(':empty')) {
                if (!$(this).hasClass("opened")) {
                    $(".content_conseil .bloc_ctn .accordion_title").removeClass("opened");
                    $(".content_conseil .bloc_ctn .accordion_text").slideUp(200);
                    $(this).addClass("opened");
                    $(this).next().slideDown(200);
                } else {
                    $(this).removeClass("opened");
                    $(this).next().slideUp(200);
                }
            }
        });
    });

    //SWIPER TG PAGE ENTRETIEN
    setTimeout(function() {
        if ($('#entretien_gondole .product_associations_wrapper').length) {
    
            var productRangesSwiper = new Swiper('.product_associations_wrapper ', {
    
                slidesPerView: 5,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.prevRange',
                    nextEl: '.nextRange',
                },
            });
        }
    }, 100);

    // PAGE ADV SAME IMG HEIGHT
    getHeight();
    $(window).resize(function () {
        getHeight();
    });

    $(document).on("click", ".productFeatTitle", function () {
        var that = $(this);
        var productFeatDescription = $('.productFeatDescription');

        if (that.hasClass('disabled')) {
            return
        } else {
        // Ferme les autres éléments, ouvre/ferme l'élément courant
        $(".productFeatTitle").not(that).removeClass("current").next(productFeatDescription).slideUp("fast");
        that.hasClass('current') ? that.removeClass('current').next(productFeatDescription).slideUp("fast") : that.addClass('current').next(productFeatDescription).slideDown("fast");
        }
    });
    
    // Product swiper initialization
    if ($(".productSwiper").length) {
        $('body').on('mouseenter touchstart', '.productSwiper', function () {
            if (!$(this).hasClass('swiper-container-initialized')) {
                var slidesPerView = $(this).hasClass('doubleCol') ? 2 : 1;

                var swiper = new Swiper($(this), {
                    slidesPerView: slidesPerView,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loop: false,
                });
            }
        });

        var listHover = document.querySelectorAll(".lightbox.smallbasketExpress .swiper-slide");
        listHover.forEach(item => item.classList.add('toast'));
    }
    
    /*Set new path for image on checkout page*/
    if ($('body').hasClass('checkout')) {
        var img = $('#bank_div .bank_subtitle span:eq(1) img.bank_locks');
        
        if (img.length) {
            var src = img.attr('src');
            var newSrc = src.replace(/.*\/img\//, '/img/');
            img.attr('src', newSrc);
        }
    }
});

/***** PROD ASSOCS DATA LAYERS *****/

function dataLayer_associated_product() {

    if (window.$('#associations_slider')) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';

        if (product_id !== '') {
            var productObj = data_layer_products[product_id];

            var prod = productObj;
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': { 'list': productObj['category'] },      // Optional list property.
                        'products': [prod]
                    }
                },
                'eventCallback': "function () {document.location = productObj.url}"
            });
        }
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/
function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}
function process_type_preco() {
    var val = $(".bloc_lightbox_preco.actif").attr("data-value");
    $.ajax({
        type: "post",
        data: { type_preco: val },
        url: path_relative_root + create_link("ajax_choose_preco.php"),
        success: function (response) {
            if (response.status == "success") {
                if ($("input[name=preco_choice]").length == 0) {
                    closeMultiShad("lightbox_preco");
                }
                var index_conveyor =
                    typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function (i, item) {
                    array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                    array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                    array_transporteurs_datas[i]["fdp"]["format"] =
                        item["format"];
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                        i +
                        "] .pricetag"
                    ).html(item["format"]);
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                        i +
                        "] .price_tag"
                    ).html(item["format"]);
                });

                if (val == "several") {
                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {
                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}
$(".bloc_lightbox_preco").on("click", function () {
    if (!$(this).hasClass("actif")) {
        $(".bloc_lightbox_preco.actif").removeClass("actif");
        $(this).addClass("actif");
    }
});

function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' + Translator.translate(fieldname + "_mobile") +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                        fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' + Translator.translate(fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

/**
Sizeguide lightbox */
function openSizeguide() {
    openMultiShad('sizeguide_light');
}

// BUTTON UP
if ("body.recycle", "body.la_marque") {
    var backToTop = $(".button_up");
    backToTop.click(function () {
        $("html,body").animate({
            scrollTop: 0
        }, "slow")
    });

    $(window).on("scroll touchmove", function () {
        var o = $(window).scrollTop();
        o > 650 ? backToTop.addClass("show") : backToTop.removeClass("show")
    });
}

// SWIPER
if ($("#swiperRecycle .swiper-slide").length > 1) {
    recycleSwiper = new Swiper("#swiperRecycle", {
        loop: false,
        slidesPerView: 2.25,
        pagination: {
            el: "#swiperRecycle .swiper-pagination",
            type: "progressbar",
        }
    });
}

// SWIPER HOVER PANIER

function initSwiperHoverPanier() {
    hoverBasketSwiper = new Swiper(".gondoleInterest.swiper-container", {
        loop: true,
        slidesPerView: 3.3,
        breakpoints: {
            1440: {
                slidesPerView: 4,
            },
            1366: {
                slidesPerView: 5,
            },
        },  
    });
}

// SWIPER TUNNEL PANIER
function initSwiperTunnel() {
    if ($(".cartAssociations .swiper-slide").length >= 1) {
        tunnelBasketSwiper = new Swiper(".cartAssociations .cartAssocSwiper", {
            loop: false,
            slidesPerView: 3,
            navigation: {
                nextEl: ".swiper-cartassoc-next",
                prevEl: ".swiper-cartassoc-prev",
            },   
        });
    }

}

// CMS SWIPER
if ($('.pushProductHome').length) {

    var pushProductHome = new Swiper('.pushProductHome', {

        slidesPerView: 4,
        allowTouchMove: false,
        spaceBetween: 0,
        navigation: {
            prevEl: '.tgHomePrev',
            nextEl: '.tgHomeNext',
        },
        breakpoints: {
            // when window width is <= 1200px
            1200: {
                slidesPerView: 2,
            },
        }
    });
}

// PAGE ADV SAME IMG HEIGHT
function getHeight() {
    var getImgHeight = $("body.cms_page .cms-page-module.image.image-1 .cms-page-module-img-ctn img").height();
    $("body.cms_page .cms-page-module.image.image-1 .cms-page-module.image.sub-image-1").css("height", getImgHeight);
}

//Change paypal input position in bank page
var inputPaypal = document.querySelectorAll(".checkout.in_tunnel #inputPAYPAL");
if (inputPaypal.length > 0){
    inputPaypal = inputPaypal[0]
    var bank_subtitle = document.querySelector(".checkout.in_tunnel .paypal .bank_subtitle");
    bank_subtitle.insertAdjacentElement("beforebegin", inputPaypal)
    inputPaypal.classList.add("loadEnd" )
    bank_subtitle.classList.add("loadEnd")
}

// loader_paypal
$(".wrapper_bloc_banque.paypal").on("click touch tap", function() {
    $( this ).find(".bank_subtitle").addClass('loader_paypal');
});

//Change SOFORT input position in bank page
var inputSofort = document.querySelectorAll(".checkout.in_tunnel #inputSOFORT");
if (inputSofort.length > 0){
    inputSofort = inputSofort[0]
    var bank_subtitle = document.querySelector(".checkout.in_tunnel .sofort .bank_subtitle");
    bank_subtitle.insertAdjacentElement("beforebegin", inputSofort)
    inputSofort.classList.add("loadEnd")
    bank_subtitle.classList.add("loadEnd")
}

// pin image for lookbook
$( function () {
    $('.page_rayon.rayon_lookbook .tag').each(function(){
        //Show tags elements on mouse enter
        $(this).on('mouseenter', function(){
            $('.page_rayon.rayon_lookbook .tag').not(this).find('.tag_tooltip').fadeOut(500);
            $('.page_rayon.rayon_lookbook .tag').not(this).removeClass('hover');
            $(this).addClass('hover');
            $(this).find('.tag_tooltip').fadeIn(500);
        });

        // Hide tags elements on mouse leave tooltip
        $(this).find('.tag_tooltip').on('mouseleave', function(){
            if ($(this).parent().hasClass('hover')){
                $(this).fadeOut(500);
                $(this).parent().removeClass('hover');
            }
        });
    });
} );

//bandeau titre
$(document).ready(function() {
    const wrapperElement = $(".wrapper_bandeau_connexion");
    if (!wrapperElement.parent().hasClass('vp_connexion')) {
        const h1Element = $("<h1>vgt_ident</h1>").text(Translator.translate("vgt_ident"));
        wrapperElement.addClass("bandeau_titre").append(h1Element);
    }
});

function closeSizeNotAvailable(elem) {

    // Hide bloc bloc_size_not_available_
    $("#bloc_size_not_available_" + elem).hide();

    // Show bloc colors and size
    $("#prod_info_" + elem + " .rollover_left").show();
    $("#ligne_pointure_" + elem).show();
    $("#ligne_couleur_" + elem).show();
}

function updateCartElements() {
    if ($('#montant_txt').length > 0) {
        var amount = $('#montant_txt').html();
        amount = amount.replaceAll(/[^0-9]/g, '');

        initAlmaWidgetBasket(amount);
    }

    let nbWishlist = $(".cart_main_table.wishlist .cart_product_line").length;

    addToWishlistUpdateHeader(nbWishlist);
}

// Fonction qui reload la page en cas d'achat express dans la page panier via tête de gondole par exemple.

function mReloaded() {
    var provenance = window.location.pathname;

    provenance = provenance.substring(
        //  trouve la dernière occurrence du caractère / dans la chaîne provenance, qui contient le chemin de l'URL actuelle. En ajoutant + 1, le code se positionne juste après ce dernier /. Cela est nécessaire parce que substring commence à extraire à partir de cet indice. 
        provenance.lastIndexOf("/") + 1,
        // Si un . est présent (!== -1), l'expression retourne l'indice de ce dernier '.'. Cela détermine l'endroit où la méthode substring doit s'arrêter d'extraire la chaîne.
        provenance.lastIndexOf(".") !== -1 ? provenance.lastIndexOf(".") : provenance.length
    );

    var isPanier = new RegExp(/(?:panier|cart)/);

    if (isPanier.test(provenance)) {
        window.location.reload();
    }
}

// Page entretien -> on click menu, auto-scroll to section
$(document).ready(function() {
    $('.menu_entretien .menu-item').click(function(event) {
        event.preventDefault();
        $('.menu-item').removeClass('active'); // Remove active class from all menu items
        $(this).addClass('active');
        
        var targetId = $(this).attr('href').substring(1);
        var targetSection = $('#' + targetId);
        var offset = targetSection.offset().top - 150;
        $('html, body').animate({
        scrollTop: offset
        }, 1000);
    });
});

function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});
