var header = document.getElementById('site_head_wrap');
var trigger_recherche = document.getElementById('trigger_recherche');
var form_recherche = document.getElementById('form_recherche');
var input_search = document.getElementById('search_home');
var shad_header = document.getElementById('shad_header');
var ico_basket = document.getElementById('cart_top');
var ico_wishlist = document.getElementById('wishlist_top');
var show_top_wish = document.getElementById('show_top_wish');
var hasItem = $('.hasItem');

$(function () {

    "use strict";

    /**
     * Fix list menu width
     */
    setListeMenuWidth();

    /**
    Add class on body when pre-header is active */
    if ($('#site_head_wrap.bandeau_on').length) {

        $('body').addClass('bandeauDelivery');
    }

    if ($('.cart_main_title').length) {

        updateCartElements();

    }

    /**
     * iPAD COMPATIBLE "HOVER" EFFECT ON MAIN MENU ITEMS
     * ALLOWS REDIRECTION FOR THE SECOND "CLICK" EVENT
     */
    var mainLinkMenu = $('.mainLinkMenu');

    mainLinkMenu.on('click touch', function (event) {

        var targeted = $(this).next('.ss_menu_categ_wrapper');
        var targetLocation = $(this).attr('href');

        if (targeted.length) {

            event.preventDefault();

            if (targeted.hasClass('opened')) {

                document.location.assign(targetLocation);
            } else {

                $(".ss_menu_categ_wrapper.opened").removeClass('opened');
                targeted.addClass('opened');
            }
        } else {

            document.location.assign(targetLocation);
        }
    });

    /**
     * NECESSARY TO AVOID THE "preventDefault" ABOVE
     */
    mainLinkMenu.mouseenter(function () {

        $(this).next('.ss_menu_categ_wrapper').addClass('opened');
    }).mouseleave(function () {

        $(this).next('.ss_menu_categ_wrapper').removeClass('opened');
    });

    /**
     * HEADER HEIGHT REDUCING ON SCROLL
     */
    function onWinScroll() {

        var scrollTop = $(document).scrollTop();

        if (header.classList.contains('sticky')) {

            if (scrollTop === 0) {

                header.classList.remove('sticky');
            }
        } else {

            if (scrollTop !== 0) {

                header.classList.add('sticky');
            }
        }
    }

    $(window).on('scroll', onWinScroll);

    onWinScroll();

    setRollWidth();

    $('#search_results li').on('click', function () {

        closeRecherche();
    });

    /**
     * Deplacement des infos de commande pour la page paiement
     */
    if ($('.checkout').length) {
        $('.wrapper_payment').insertBefore($('#cart_total'));
    }

    /**
     * Rappel couleur FP
     */
    if ($('#currentSelectedColor').length) {

        var currentSelectedColor = $('#currentSelectedColor');
        var couleurProd = $('#fpRightPart .prodColor:checked');

        currentSelectedColor.html(couleurProd.data('nom'));
    }


    /**
     * Alert stock FP change placeholder
     */
    if ($('.w-alertestock-form').length) {

        var alertPlaceholder = $('.w-alertestock-form .w-input-label');
        alertPlaceholder.html(Translator.translate('your_email'));
    }

    /**
     * Set double visu placement
     */
    if ($('#list_item').length) {

        setVisuDoubleCol();
    }

    /* Init overlayScroll for guide des tailles */
    $('#container_guide .wrap_table').each(function() {
        $(this).overlayScrollbars({});
    });

    /**
     * Change p^laceholder wording on inactive page form
     */
    if ($('.image-signup-module').length) {

        var signupPlaceholder = $('.image-signup-module .w-newsletter-form .w-input-label');
        signupPlaceholder.html(Translator.translate('enter_your_mail'));
    }

    /**
     * Category SEO txt read more
     */
    if ($('.txt.trimed').length) {

        var trimedTxt = $('.txt.trimed');
        var fullTxt = $('.txt.full');
        var read_more = $('.read_more');
        var read_less = $('.read_less');

        read_more.on('click', function () {

            $(this).hide();
            read_less.show();
            trimedTxt.removeClass('displayed');
            fullTxt.addClass('displayed');
        });

        read_less.on('click', function () {

            $(this).hide();
            read_more.show();
            trimedTxt.addClass('displayed');
            fullTxt.removeClass('displayed');
        });
    }

    // Insert paragraph in bank right side
    if ($('.bank_right_side').length) {

        var bankDeliveryReminder = '<p class="bankDeliveryReminder">'+Translator.translate('bank_delivery_reminder')+'</p>';
        $('.wrapper_payment').append(bankDeliveryReminder);
    }

    // swiper store locator
    if ($('#wrapper_visus_store').length) {
        var wrapper_visus_store = new Swiper('#wrapper_visus_store', {

            slidesPerView: 5,
            allowTouchMove: false,
            navigation: {
                prevEl: '.prevRange',
                nextEl: '.nextRange',
            },
            breakpoints: {
                1200: {
                    slidesPerView: 3,
                },
            }
        });
    }

    // Add touch compatibility to "Disponibilité en magasin" for tablets -> Fiche produit
    if ($('body.product_page')) {
        addTouchListener('#ctaAvailabilityInStore');

        // Create a MutationObserver to observe changes to "#ctaAvailabilityInStore"
        let observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.type === 'childList') {
                    if ($('#ctaAvailabilityInStore').length > 0) {
                        addTouchListener('#ctaAvailabilityInStore');
                    }
                }
            });
        });
        
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }
});

/**
 * Fix list menu width to avoid flicking when hovering a link in it (due to font-family switch on mouseover)
 */
function setListeMenuWidth() {

    $('.submenu_wrapper .ss_cat').each(function () {

        $(this).css('width', $(this).width());
    });
}
window.addEventListener('resize', setListeMenuWidth);

/**
 * Menu switch lang
 */
$('.switchLangWrapper').on('mouseenter', function () {

    displaySwitchLang();
});

$('.switchLangWrapper').on('mouseleave', function () {

    hideSwitchLang();
});

function displaySwitchLang() {

    var switchLangWrapper = $('.switchLangWrapper');
    var switchLang = $('.switchLang');

    switchLang.slideDown(200, function () {

        switchLang.addClass('opened');
        $('.menu_part.bottom').scrollTop(1500);
        switchLangWrapper.addClass('opened');
    });
}

function hideSwitchLang() {

    var switchLangWrapper = $('.switchLangWrapper');
    var switchLang = $('.switchLang');

    switchLang.slideUp(200, function () {

        switchLang.removeClass('opened');
        switchLangWrapper.removeClass('opened');
    });
}

/**
 * LIGHTBOX OPENING
 */
function openMultiShad(id) {

    $("#" + id).addClass("actif");

    $("#shad").addClass("actif").on("click", function () {

        closeMultiShad(id);
    });

    if (id == "selection-offer") {

        setTimeout(function () {

            $("#selection-offer-content").lionbars();
            var color_list = new Swiper('.color_list', {

                spaceBetween: 15,
                slidesPerView: 4,
                navigation: {
                    nextEl: '.nextColour',
                    prevEl: '.prevColour',
                },
            });

            var size_list = new Swiper('.size_list', {

                spaceBetween: 15,
                slidesPerView: 4,
                navigation: {
                    nextEl: '.nextSize',
                    prevEl: '.prevSize',
                },
            });
        }, 500);
    }

    if (id == "lightbox_achat_express") {

        setTimeout(function () {

            var expressLider = new Swiper('#expressLider', {

                slidesPerView: 1,
                preloadImages: false,
                lazy: true,
                navigation: {
                    prevEl: '.expressLiderPrev',
                    nextEl: '.expressLiderNext',
                },
            });
        }, 500);
    }

    if (id == "zoomBox") {

        $('html, body').animate({ scrollTop: 0 }, 500, 'linear');
        $("#shad").addClass("forZoom");
    }

    if (id == "sizeguide_light") {

        $('#sizeguideScroll').lionbars({});
    }
}

/**
 * LIGHTBOX CLOSING
 */
function closeMultiShad(id) {
    
    $('#' + id).removeClass('actif');
    if (!$('.lightbox.actif').length) {
        $('#shad').removeClass('actif');
    }

    if (id == "zoomBox") {

        $("#shad").removeClass("forZoom");
    }
}

/**
 * SEARCH MAIN
 */
trigger_recherche.addEventListener('click', function () {

    header.classList.add('noTrans');
    trigger_recherche.classList.add('actif');
    form_recherche.classList.add('appear');
    shad_header.classList.add('actif');

    shad_header.addEventListener('click', function () {

        closeRecherche();
    });
});

$('#search_home').keypress(function (e) {
    var key = e.which;

    if (key == 13 && $('#search_home').val().length >= 3) {
        closeRecherche();
    }
});

function closeRecherche() {

    if (form_recherche.classList.contains('appear')) {

        header.classList.remove('noTrans');
        trigger_recherche.classList.remove('actif');
        form_recherche.classList.remove('appear');
        shad_header.classList.remove('actif');
    }
}

$('.wrap_search_field .closeBtn').on("click touch", function () {

    if (input_search.value === '') {

        closeRecherche();
    } else {

        input_search.value = '';
        input_search.focus();
    }
});

function openSearch() {

    header.classList.add('noTrans');
    trigger_recherche.classList.add('actif');
    form_recherche.classList.add('appear');
    shad_header.classList.add('actif');
    input_search.value = '';
    input_search.focus();

    shad_header.addEventListener('click', function () {

        closeRecherche();
    });
}

/**
 * SIZE FOR ACCOUNT / BASKET / WISHLIST ROLLOVER
 */
function setRollWidth() {

    var totalWidth = $(window).innerWidth();
    var rollAccountEntries = $('.rollAccountEntries');
    var head_top = $('.head_top');
    var head_topWidth = head_top.outerWidth();
    var desiredWidth = 388 + (totalWidth - head_topWidth) / 2;

    if (totalWidth > 1920) {

        rollAccountEntries.css('width', desiredWidth + "px");
    }
}

window.onload = setRollWidth;
window.onresize = setRollWidth;

/**
 * ACCOUNT ROLLOVER
 */
var ico_account = document.querySelector('.my_account');

ico_account.addEventListener('mouseenter', function () {

    if (ico_account.classList.contains('is_logged')) {

        ico_account.classList.add('hover');
        setTimeout(function () {

            if (ico_account.classList.contains('hover')) {

                header.classList.add('shaded');
                if (!$('#form_recherche').hasClass('appear')) {

                    shad_header.classList.add('actif');
                }
            }
        }, 250);
    }
});

ico_account.addEventListener('mouseleave', function () {

    ico_account.classList.remove('hover');
    header.classList.remove('shaded');
    if (!$('#form_recherche').hasClass('appear')) {

        shad_header.classList.remove('actif');
    }
});

/**
 * BASKET ROLLOVER
 */
function topCartAppear(elem) {

    var rolloverTxt = elem.children('.rollAccountEntries');
    rolloverTxt.addClass('appear');

    setTimeout(function () {

        if (elem.hasClass('hover')) {

            header.classList.add('shaded');
            if (!$('#form_recherche').hasClass('appear')) {

                shad_header.classList.add('actif');
            }
        }
    }, 250);
}

function topCartDesappear() {

    $('.rollAccountEntries').removeClass('appear');
    header.classList.remove('shaded');
    if (!$('#form_recherche').hasClass('appear')) {

        shad_header.classList.remove('actif');
    }
}

$(document).ready(function() {
    // Using event delegation to handle '.hasItem' which appear after the initial load of the page
    $(document).on('mouseenter', '.hasItem', function() {
        topCartAppear($(this));
    });

    $(document).on('mouseleave', '.hasItem', function() {
        topCartDesappear();
    });
});

$(document).on("click", '.thumblnk', function () {

    var id_pannel = this.getAttribute('data-pannel');
    var thumbnav = $(this).closest('.thumbnav');

    $('.thumblnk').removeClass('current');
    $('.thumbslide').removeClass('current');

    $('.thumblnk[data-pannel=' + id_pannel + ']').addClass('current');
    $('.thumbslide[data-pannel=' + id_pannel + ']').addClass('current');
});

if ($('.promo_swiper').length && $('.promo_swiper .swiper-slide').length > 1) {

    var promo_swiper = new Swiper('.promo_swiper', {

        slidesPerView: 1,
        loop: true,
        speed: 19000,
        autoplay: {

            delay: 1,
        },
        allowTouchMove: false,
    });
}

$(document).on("click touch", ".remove_top_wishlist", function (ev) {
        var id = $(this).attr("data-id");
        var prodId = $(this).attr("data-produit-id");
        var colorId = $(this).attr("data-color-id");
        var sizeId = $(this).attr("data-size-id");

        let datas = {
            id: id,
            remove: true
        };

        if (prodId !== undefined) {
            datas.product_id = prodId;
            id = prodId;
        }

        if (colorId !== undefined) {
            datas.color_id = colorId;
        }

        if (sizeId !== undefined) {
            datas.size_id = sizeId;
        }

        $.post(path_relative_root + create_link("ajax_remove_from_wishlist.php"), datas, function (data) {
            var nr;

            if (data.result == undefined) {
                data.result = $('.wish_liste_product .product_ctn').length;

            } else {
                nr = data.result.wishlistLength;
            }

            if (nr == 0) {
                $("#wishlist_top .monpanier").html('');
                $("#show_top_cart").addClass('hide');
                $(".item.wishlist").hide();
                topCartDesappear();
            } else {
                $("#wishlist_top .monpanier").html(nr);
            }

            var response_html = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));

            $("#wishlist_top").html(response_html);

            $("#wproduct_" + id).fadeOut(100, function () {
                $(this).remove();
            });

            if ($('#prod_info_' + id).length) {
                var addToWishlistButtonOnFp = $('#prod_info_' + id + ' #addToWishlistButton');

                if (addToWishlistButtonOnFp.hasClass('existToWishlistButton')) {
                    addToWishlistButtonOnFp.removeClass('existToWishlistButton');
                }
            }
        });
    });

/**
 * Rsponsive upselling sliders from product page
 */
function setUpsellFicheProd() {
    var windowWidth = $(window).width();

    if (windowWidth < 1300) {
        if ($('#productAssociationSwiper').length) {
            var productAssociationSwiper = new Swiper('#productAssociationSwiper', {

                slidesPerView: 3,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.prevAssoc',
                    nextEl: '.nextAssoc',
                },
            });
        }

        if ($('#productRangesSwiper').length) {

            var productRangesSwiper = new Swiper('#productRangesSwiper', {

                slidesPerView: 3,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.prevRange',
                    nextEl: '.nextRange',
                },
            });
        }
    } else if (windowWidth < 1550) {

        if ($('#productAssociationSwiper').length) {
            var productAssociationSwiper = new Swiper('#productAssociationSwiper', {

                slidesPerView: 4,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.prevAssoc',
                    nextEl: '.nextAssoc',
                },
            });
        }

        if ($('#productRangesSwiper').length) {

            var productRangesSwiper = new Swiper('#productRangesSwiper', {

                slidesPerView: 4,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.prevRange',
                    nextEl: '.nextRange',
                },
            });
        }
    } else if (windowWidth >= 1550) {

        if ($('#productAssociationSwiper').length) {
            var productAssociationSwiper = new Swiper('#productAssociationSwiper', {

                slidesPerView: 5,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.prevAssoc',
                    nextEl: '.nextAssoc',
                },
            });
        }

        if ($('#productRangesSwiper').length) {

            var productRangesSwiper = new Swiper('#productRangesSwiper', {

                slidesPerView: 5,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.prevRange',
                    nextEl: '.nextRange',
                },
            });
        }
    }
}

if ($('#productAssociationSwiper').length || $('#productRangesSwiper').length) {
    setUpsellFicheProd();
    $(window).on('resize', function () {
        setUpsellFicheProd();
    });
}

if ($('#coverSwiper').length) {

    // If more than 1 slide
    if ($('#coverSwiper .swiper-slide').length > 1) {

        var coverSwiper = new Swiper('#coverSwiper', {

            slidesPerView: 1,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 4000,
            },
            navigation: {
                prevEl: '.coverSwiperPrev',
                nextEl: '.coverSwiperNext',
            },
        });
    } else {

        var coverSwiper = new Swiper('#coverSwiper', {

            slidesPerView: 1,
        });
    }
}

if ($('.homeFourImage').length) {

    var homeFourImage = new Swiper('.homeFourImage', {

        slidesPerView: 4,
        preloadImages: false,
        lazy: true,
        draggable: false,
        allowSlidePrev: false,
        allowSlideNext: false,
        allowTouchMove: false,
        navigation: {
            prevEl: '.homeFourImagePrev',
            nextEl: '.homeFourImageNext',
        },
        breakpoints: {
            1680: { // when window width is <= 1680px
                spaceBetween: 28,
            },
        }
    });
}

if ($('.pushProductHome').length) {

    var pushProductHome = new Swiper('.pushProductHome', {

        slidesPerView: 4,
        allowTouchMove: false,
        // Avoid white spaces between img on homepage after sliding
        spaceBetween: -1,
        navigation: {
            prevEl: '.tgHomePrev',
            nextEl: '.tgHomeNext',
        },
        breakpoints: {
            // when window width is <= 1200px
            1200: {
              slidesPerView: 2,
            },
        }
    });
}

if ($('#sliderHomeInsta').length) {

    var sliderHomeInsta = new Swiper('#sliderHomeInsta', {

        slidesPerView: 3.9,
        spaceBetween: 4,
        navigation: {
            prevEl: '.instaPrev',
            nextEl: '.instaNext',
        },
        breakpoints: {
            // when window width is <= 1200px
            1200: {
              slidesPerView: 2.2,
              spaceBetween: 4
            },
        }
    });
}

if ($('.slider_marquee').length && $('.slider_marquee .swiper-slide').length) {

    var slider_marquee = new Swiper('.slider_marquee', {

        slidesPerView: 1,
        loop: true,
        speed: 22000,
        spaceBetween: 15,
        autoplay: {

            delay: 0,
        },
        allowTouchMove: false,
    });

    if ($(window).width() < 1410) {
        var capusule_txt = Translator.translate('la_capsule_repeat_b');
        $('.landing_page_content.bloc_six .slider_marquee span.txt').replaceWith(('<span class="txt">' + capusule_txt + '</span>'));

        var tops_moods_txt = Translator.translate('tops_moods_b');
        $('.landing_page_content.slider_content .slider_marquee.x2 span.txt').replaceWith(('<span class="txt">' + tops_moods_txt + '</span>'));

    }
}

if ($('.slider_marquee_img_a').length && $('.slider_marquee_img_a .swiper-slide').length) {

    var slider_marquee_img_a = new Swiper('.slider_marquee_img_a', {

        slidesPerView: 'auto',
        loop: true,
        speed: 18000,
        spaceBetween: 10,
        autoplay: {
            delay: 0,
        },
        allowTouchMove: false,
    });
}


if ($('.slider_marquee_img').length && $('.slider_marquee_img .swiper-slide').length) {

    var slider_marquee_img = new Swiper('.slider_marquee_img', {

        slidesPerView: 'auto',
        loop: true,
        speed: 16000,
        spaceBetween: 10,
        autoplay: {
            delay: 0,
        },
        allowTouchMove: false,
    });
}

if ($('.marquee_txt .swiper-slide').length) {

    var marquee_txt = new Swiper('.marquee_txt', {
        spaceBetween: 0,
        centeredSlides: true,
        speed: 2500,
        autoplay: {
            delay: 0,
        },
        loop: true,
        slidesPerView: 'auto',
        allowTouchMove: false,
        disableOnInteraction: true,
    });
}

/* BACK TO TOP RAYON */

$(document).ready(function () {

    var stickyNavEl = $('#rayon_sticky_nav');
    var backToTopBtn = $('#back_to_top');

    if (stickyNavEl.length > 0) {
        var stickyNavTop = stickyNavEl.offset().top;
    }

    function stickyNav() {
        if (stickyNavEl.length > 0) {
            var scrollTop = $(window).scrollTop(); // our current vertical position from the top

            if (scrollTop > stickyNavTop) {
                stickyNavEl.addClass('sticky');
                $("#cart_top").appendTo(".filters_left");

            } else {
                stickyNavEl.removeClass('sticky');
                $("#cart_top").appendTo(".rightSide");
            }
        }
    };

    stickyNav();

    $(window).scroll(function () {
        stickyNav();

        if ($(window).scrollTop() > 300) {
            backToTopBtn.addClass('show');
        } else {
            backToTopBtn.removeClass('show');
        }
    });

    backToTopBtn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, 500, 'linear');
    });

});




$(function () {
    setTimeout(function() {

        $('.category.boutique .item a.blockInfosProd .wrapper_description .item_title').each(function () {
            var item_title_boutiqueh2 = $(this);
            $(this).replaceWith('<h2 class="item_title">' + item_title_boutiqueh2.text() + '</h2>');
        });

        if ($(".wrapper_top_category .leftFilter a").hasClass('actif')) {
            $('.wrapper_top_category .leftFilter a h2').each(function () {
                var sous_rayon_name_souscateg = $(this);
                $(this).replaceWith('<h2 class="title sous_rayon_name">' + sous_rayon_name_souscateg.text() + '</h2>');
            });
        }

        $('.body_login .wrapper_tunnel .newlogin_page .login_bloc .login_form_wrapper .title h3').each(function () {
            var moncompteh3 = $(this);
            $(this).replaceWith('<h2 class="h3style">' + moncompteh3.text() + '</h2>');
        });

        $('#cookiesLightbox section h4').each(function () {
            var cookiesh4 = $(this);
            $(this).replaceWith('<p class="cookiesh4">' + cookiesh4.html() + '</p>');
        });

    }, 1000);

    /**
     * CMS SLIDERS
     */
    if ($('.coverSlider').length) {

        var coverSlider = new Swiper('.coverSlider', {

            slidesPerView: 1,
            loop: true,
            speed: 14000,
            spaceBetween: 10,
            autoplay: {

                delay: 1,
            },
            allowTouchMove: false,
        });
    }

    if ($('.cmsBlocsTxt').length) {

        var cmsBlocsTxt = new Swiper('.cmsBlocsTxt', {
            slidesPerView: 4,
            spaceBetween: 16,
            navigation: {
                nextEl: '.swiper-button-next.txt_4_btn',
                prevEl: '.swiper-button-prev.txt_4_btn',
            },
        });
    }

    if ($('.cmsDefile1').length) {

        var cmsDefile1 = new Swiper('.cmsDefile1', {

            slidesPerView: 3,
            preloadImages: false,
            lazy: true,
        });
    }

    if ($('.cmsDefile2').length) {

        var cmsDefile2 = new Swiper('.cmsDefile2', {

            slidesPerView: 2,
            preloadImages: false,
            lazy: true,
        });
    }

    if ($('.cmsDelileTxt').length) {

        var cmsDelileTxt = new Swiper('.cmsDelileTxt', {

            slidesPerView: 1,
            loop: true,
            speed: 14000,
            autoplay: {

                delay: 1,
            },
            allowTouchMove: false,
        });
    }

    if ($('.line-text ').length) {

        var lineText = new Swiper('.line-text ', {

            slidesPerView: 1,
            loop: true,
            speed: 14000,
            spaceBetween: 10,
            autoplay: {

                delay: 1,
            },
            allowTouchMove: false,
        });
    }

    if ($('.swiper_ambassador').length) {
        var ambassador = new Swiper('.swiper_ambassador', {
            navigation: {
                nextEl: '.btn_next',
                prevEl: '.btn_prev',
            },
        });
    }

    // Ajout de CTA sur la page connexion.php
    if ($('body').hasClass('body_login')) {
        const emailInput = $('input[name="userbdd"]')
        const translatedText = Translator.translate('login_en_savoir_plus')

        emailInput.on('input paste change', () => {
            setTimeout(() => {
                if ($('.content_form.sponsor').length && !$('.en_savoir_plus_cta').length) {
                    $('.wrapper_input_sponsor').prepend(`<a href="${path_relative_root + create_link('faq')}?theme=5#&source=cta" class="en_savoir_plus_cta" target="_blank">${translatedText}</a>`);
                }
            }, 250);
        });
    }

    if ($('body').hasClass('faq')) {
        $(document).ready(function () {
            const targetUrlSubstring = "faq.php?theme=5#";
            const ctaSource = "&source=cta";
            const currentUrl = window.location.href;

            if (currentUrl.includes(targetUrlSubstring) && currentUrl.includes(ctaSource)) {
                const parrainageQuestion = $(".faq_question_75");
                const parrainageReponse = $(".faq_reponse_75");

                if (parrainageQuestion.length && parrainageReponse.length) {
                    parrainageQuestion.addClass("active");
                    parrainageReponse.show();

                    setTimeout(() => {
                        $('html, body').animate({
                            scrollTop: parrainageQuestion.offset().top - 105
                        }, 2000);
                    }, 1000);
                }
            }
        });
    }

    $('#jsCookieCustom').on('click', function () {
        $('.cookiesLightbox_accept_btn').addClass('moveDownAccept');
        $('.cookiesLightbox_custom_btn').addClass('moveDownCustom');
    })
});

if ($('.gondole_x3 .swiper-slide').length > 3) {
    var img_txt_x3 = new Swiper('.gondole_x3', {
        slidesPerView: 4,
        loop: false,
        spaceBetween: 15,
        navigation: {
            nextEl: '.swiper-button-next.x3',
            prevEl: '.swiper-button-prev.x3',
        },
    });
}

/**
 * Set double visu placement (Ray + Search)
 */
function setVisuDoubleCol() {

    $('#list_item .item:nth-child(22n+11), #list_item .item:nth-child(22n+20)').each(function () {

        $(this).addClass('doubleColItem');
    });

    $('#list_item .item.doubleColItem .imgWrapper .productSwiper').each(function () {

        $(this).addClass('doubleCol');
    });
}


function triggerDropdown(elem, trigger, toDrop) {

    if (!$(elem).hasClass('actif')) {
        $(toDrop+'.actif').slideUp(400).removeClass('actif');
        $(trigger +'.actif').removeClass('actif');
        $(elem).addClass('actif');
        $(elem).next(toDrop).addClass('actif').slideDown(400);
    } else {
        $(trigger+'.actif').removeClass('actif');
        $(toDrop+'.actif').slideUp(400).removeClass('actif');
    }
}

if ($('#cookiesLightbox input').length) {
    var inputCookies = $('#cookiesLightbox input');
    var changeTxtToAccept = $('#cookiesLightbox div.w-form-line.w-submit.form_submit #js_cookie_accept span');

    inputCookies.on('change', function () {
        changeTxtToAccept.text(Translator.translate('accept'));
    });
}

/* Ancre page FAQ */
$(document).ready(function () {
    if ($('#theme_global').length) {
        $('a[href*=#]:not([href=#])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top-120 // valeur équivalent à la hauteur à dépasser
                    }, 800);
                }
            }
        });

        $( window ).load(function() {  
            if($(location.href.split("#")[1])) {
                var target = $('#'+location.href.split("#")[1]);
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top-120 // valeur équivalent à la hauteur à dépasser
                    }, 800);
                }
            }
        });

        var faq_anchor_name = window.location.hash.substring(1);
        var faq_anchor_name_element = $('#' + faq_anchor_name);

        if (~window.location.href.indexOf("#" + faq_anchor_name)) {
            $('#theme_page').addClass('actif');
            faq_anchor_name_element.addClass('active');
            faq_anchor_name_element.find('.faq_questions').show();
        }
    }
});

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + create_link('order_delivery_ajax'),
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.error(e);
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};

// Show not dynamic menu Collection materials
function showitems (el) {
    if ($('.ss_cat.not-dynamic').length) {
        var menuWrapper = $(el);
        var itemList = $(el).find('.cat_list');
        var ss_cat_name = $(el).find('.ss_cat_name');

        menuWrapper.toggleClass('triggered');
        itemList.slideToggle();
        ss_cat_name.toggleClass('actif');
        $('.ss_cat_name').not(ss_cat_name).removeClass('actif');
        $('.cat_list').not(itemList).slideUp();
    }
}

// Capture onclick attritube and add an ontouch attritube too, with the same value, for tablet compatibility.
function addTouchListener(element) {
    let onClickValue = $(element).attr('onclick');

    $(element).attr({
        'ontouch': onClickValue
    });
}